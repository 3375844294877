


























import { InputSetups } from "@/mixins/input-setups";
import { PostMessage } from "../../PostMessage";
import EditorButtonsHelpView from "@/components/EditorButtonsHelpView.vue";

import { MessageEditorWithMediaTab } from "piramis-base-components/src/components/NewMessageEditors/types";
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator';

@Component({
  data() {
    return {
      MessageEditorWithMediaTab,
      EditorButtonsHelpView
    }
  },
  components:{
    MultiMessageEditorWithMediaInput
  }
})
export default class PostActionMessageSetup extends Mixins(InputSetups) {
  @VModel() postMessage!: PostMessage

  @Prop() disabled!: boolean

  @Prop() buttons!: any

  @Prop() settingsStructure!: any

  @Prop({ type: Array, default: () => [], required: false }) extraTabs!: Array<MessageEditorWithMediaTab>

  get inputButtons() {
    if (this.buttons) {
      return this.buttons
    } else {
      return this.getNewDefaultButtons()
    }
  }

  get inputSettingsStructure() {
    const defaultSettings = {
      'disable_link_preview': false,
    }

    return Object.assign(defaultSettings, this.settingsStructure)
  }
}
